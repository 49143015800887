import { faCheckCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Slide } from 'react-slideshow-image';
import axios from 'axios'
function ModalCBD({closeFn=()=>null, fill,title}){
    var [name, setName] = useState('')
    var [email, setEmail] = useState('')
    var [phone, setPhone] = useState('')
    var [tuay, setTuay] = useState('')
    var [success, setSuccess] = useState(false)
    var location = window.location.href
    const sendEmail = (e)=>{
        e.preventDefault()
        var url = process.env.NODE_ENV == 'production' ? 'https://api.merchantreferral.partners/sendmail':'http://api.mysite.localhost:8081/sendmail'
        axios.post(url,{name, email,phone,tuay,location},
        {headers:{
                'Authorization': ``
        }
        }).then(res=>setSuccess(true)).catch(e=>console.log(e))
    }
    return(
        <div>
            <div className="modal z-50 h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-20 px-5 overflow-y-auto">
                {!success?
                <div className="bg-white rounded shadow-lg lg:w-1/2 w-full">
                    <Slide indicators={true} arrows={false} easing="ease" className="">
                        <div className="px-10 py-20">
                            <div>
                                <h2 className="section-title font-bold mb-3">Challenge #1 in your CBD Business</h2>
                                <p>
                                    The CBD market has exploded. The passing of the Farm Bill has been a blessing for so many, however; it doesn't 
                                    provide the protections to the CBD industry that it should. Although CBD has been removed from the controlled 
                                    substances list, banking is still a challenge due to the vast regulations in the industry.  
                                </p>
                            </div>
                        </div>
                        <div className="px-10 py-20">
                            <div>
                                <h2 className="section-title font-bold mb-3">Challenge #2 in your CBD Business</h2>
                                <p>
                                    Public perception of the CBD space is ever changing. The lack of understanding by financial institutions of not 
                                    only the Farm Bill, but also the hemp/CBD market in general, increases confusion and misunderstanding in the market. 
                                    Because of this, finding a financial institution that will see your business as a vital partnership not only working 
                                    with your merchant services, but also providing a safe place for your depository accounts.
                                </p>
                            </div>
                        </div>
                        <div className="px-10 py-20">
                            <div>
                                <h2 className="section-title font-bold mb-3">Challenge #3 in your CBD Business</h2>
                                <p>
                                    Many banks shy away from the Hemp/CBD marketplace because the legality of Hemp/CBD is still unclear. Many standard 
                                    merchant processing institutions will either make your business jump through hoops to be able to process payments, 
                                    possibly shut you down or will not work with you at all.
                                </p>
                            </div>
                        </div>
                    </Slide>
                </div>
                :
                <div class="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow bg-white ">
                <div class="">
                    <div class="text-center p-5 flex-auto justify-center">
                        <FontAwesomeIcon className="text-6xl flex items-center mx-auto text-green-500" icon={faCheckCircle}/>
                        <h2 class="text-xl font-bold pt-3 pb-4 ">Your Form Has Been Sent!</h2>
                        <p class="text-sm text-gray-500 px-8">Your form has been successfully submitted, <u>you will receive a copy of
                            this form to your email.</u></p>
                    </div>
                    <div class="p-3  mt-2 text-center space-x-4 md:block">
                        <a onClick={closeFn}
                        class="cursor-pointer mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-lg hover:bg-gray-100">
                        Close
                        </a>
                    </div>
                </div>
                </div>
                }
                
            </div>
        </div>
    )
}
export default ModalCBD;