import React, { useState } from 'react';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
import'../../styles/cbd-style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateLeft, faCannabis, faCreditCard, faDollarSign, faMobileButton, faShield, faUsers,faCapsules, faGamepad, faFootball, faDroplet, faHeadset } from '@fortawesome/free-solid-svg-icons'
import Layout from '../../components/Layout';
import ModalCBD from '../../components/ModalCBD';
import { CSSTransition } from 'react-transition-group';


function Index(props) {
    const [modalOpen, setModal] = useState(false)
    const closeModal = () => {
        setModal(false)
    }
    let styles ={
        hero:{
            position:'relative',
            minHeight:'700px',
            width:'100%',
            backgroundImage:"url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651162961/new-cwa/CBD/pexels-alesia-kozik-8330985_wbkot9.png')",
            backgroundPosition:'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize:'cover',
            backgroundBlendMode:'multiply'    
        }
    }
    
    return (
        <div>
            <Layout title={'CBD'}>
            { modalOpen ?<ModalCBD closeFn={closeModal}/>:null}
            <svg width="0" height="0">
                <linearGradient id="bg" gradientTransform="rotate(90)">
                <stop offset="70%"  stop-color="#0099FF" />
                <stop offset="100%" stop-color="#096FAF" />
                </linearGradient>
            </svg>
            <svg width="0" height="0">
                <linearGradient id="gg" gradientTransform="rotate(90)">
                <stop offset="40%"  stop-color="#14CF74" />
                <stop offset="100%" stop-color="#049850" />
                </linearGradient>
            </svg>
            <section className="h-1/2 w-full flex lg:bg-gray-50" style={styles.hero}>
                <div className="m-auto section ">
                    <div className='px-5 lg:w-1/2'>
                        <h1 className="font-bold mb-3" style={{fontSize:'55px', lineHeight:'55px'}}>Maximize your CBD business with minimal financial risk</h1>
                        <p className="font-opensans leading-1">
                            We are the LARGEST High Risk processor in the U.S. and have deep knowledge of the unique challenges facing merchants 
                            in specialty categories like the Hemp/CBD industry.
                        </p>
                        <button onClick={()=>setModal(true)}  className="mt-5 rounded hero-button text-lg">Learn More</button> 
                    </div>                   
                </div>             
            </section>

            <section className='relative cbd-paymentsolutions-section'>
                <div className="section mx-auto px-5 py-20 w-100 overflow-hidden">
                    <div className="lg:w-1/2 text-center mx-auto mb-10">
                        <p className="text-sm subtitle uppercase cw-text-gray">what we offer</p>
                        <h2 className="section-title font-bold mb-3">Payment Solutions We Offer</h2>
                        <p className='text-sm lg:text-base'>Choosing a credit card processor or a merchant services provider is about more than rates it's about transparency, understanding, 
                            and partnership, and that is what we provide to our partners and clients.
                        </p>
                    </div>
        
                    {/* <img className="z-0 absolute -right-52 overflow-hidden" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650993719/new-cwa/Vets/Lane_3000_ch82co.png'/> */}               
                    <div className="z-50 grid grid-cols-1 md:col-span-2 lg:grid-cols-3 gap-10">
                        <div className="rounded-lg p-5 inline-flex">
                            <div className="w-1/12 mr-10 pt-2">
                                <div class="cw-bg-cbd rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-greengradient" icon={faUsers} /></div>
                                {/* <span><FontAwesomeIcon className="cw-bg-cbd rounded-full p-2 w-8 h-8 text-xl cw-text-greengradient" icon={faUsers} /></span> */}
                            </div>
                            <div className="w-11/12 space-y-2">            
                                <h3 className="font-bold text-md uppercase font-worksans">Customer Vault</h3>
                                <p className="text-sm leading-6" >Allows you to store the customer’s cardholder information to make billing the customer secure and easy for future sales</p>
                            </div>
                        </div>
                        <div className="rounded-lg p-5 inline-flex">
                            <div className="w-1/12 mr-10 pt-2">
                                <div class="cw-bg-cbd rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-greengradient" icon={faCreditCard} /></div>
                                {/* <span><FontAwesomeIcon className="cw-bg-cbd rounded-full p-2 w-8 h-8 text-xl cw-text-greengradient" icon={faCreditCard} /></span> */}
                            </div>
                            <div className="w-11/12 space-y-2">            
                                <h3 className="font-bold text-md uppercase font-worksans">Card-Present Solutions</h3>
                                <p className="text-sm leading-6" >Our card readers are also EMV Chip compatible so they will not need to be replaced as chip cards become the norm.</p>
                            </div>
                        </div>
                        <div className="rounded-lg p-5 inline-flex">
                            <div className="w-1/12 mr-10 pt-2">
                                <div class="cw-bg-cbd rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-greengradient" icon={faArrowRotateLeft} /></div>
                                {/* <span><FontAwesomeIcon className="cw-bg-cbd rounded-full p-2 w-8 h-8 text-xl cw-text-greengradient" icon={faArrowRotateLeft} /></span> */}
                            </div>
                            <div className="w-11/12 space-y-2">            
                                <h3 className="font-bold text-md uppercase font-worksans">Recurring Billing</h3>
                                <p className="text-sm leading-6" >Gives you the flexibility to automatically charge a client a specific amount on a prearranged schedule.</p>
                            </div>
                        </div>
                        <div className="rounded-lg p-5 inline-flex">
                            <div className="w-1/12 mr-10 pt-2">
                                <div class="cw-bg-cbd rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-greengradient" icon={faShield} /></div>
                                {/* <span><FontAwesomeIcon className="cw-bg-cbd rounded-full p-2 w-8 h-8 text-xl cw-text-greengradient" icon={faShield} /></span> */}
                            </div>
                            <div className="w-11/12 space-y-2">            
                                <h3 className="font-bold text-md uppercase font-worksans">Wellness Plan</h3>
                                <p className="text-sm leading-6" >Store the customer's cardholder information to make billing the customer secure and easy</p>
                            </div>
                        </div>
                        <div className="rounded-lg p-5 inline-flex">
                            <div className="w-1/12 mr-10 pt-2">
                                <div class="cw-bg-cbd rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-greengradient" icon={faMobileButton} /></div>
                                {/* <span><FontAwesomeIcon className="cw-bg-cbd rounded-full p-2 w-8 h-8 text-xl cw-text-greengradient" icon={faMobileButton} /></span> */}
                            </div>
                            <div className="w-11/12 space-y-2">            
                                <h3 className="font-bold text-md uppercase font-worksans">Mobile Device</h3>
                                <p className="text-sm leading-6" >Allows you to take payments anywhere (i.e., Mobile Locations, House Calls, etc)</p>
                            </div>
                        </div>
                        <div className="rounded-lg p-5 inline-flex">
                            <div className="w-1/12 mr-10 pt-2">
                                <div class="cw-bg-cbd rounded-full flex items-center justify-center w-12 h-12" ><FontAwesomeIcon className=" text-2xl cw-text-greengradient" icon={faDollarSign} /></div>
                                {/* <span><FontAwesomeIcon className="cw-bg-cbd rounded-full p-2 w-8 h-8 text-xl cw-text-greengradient" icon={faDollarSign} /></span> */}
                            </div>
                            <div className="w-11/12 space-y-2">            
                                <h3 className="font-bold text-md uppercase font-worksans">Electronic Invoicing</h3>
                                <p className="text-sm leading-6" >Provides the convenience to your customers to pay their invoice online</p>
                            </div>
                        </div>
                    </div>               
                </div>
            </section>
            
            
            <section className='relative '>
                <div className="section mx-auto px-5 pb-20 pt-10 w-100">
                    <div className="lg:w-1/2 text-center mx-auto mb-10">
                        {/* <p className="text-sm subtitle uppercase cw-text-gray">what we offer</p> */}
                        <h2 className="section-title font-bold mb-3">Solutions Every Challenge</h2>
                        <p>We are a partner that specializes in the development, implementation, and management of electronic payment processing products 
                            and solutions.
                        </p>
                    </div>
                    <div className='cw-bg-cbd py-5 px-5 lg:px-10 rounded-lg'>
                        <Slide indicators={true} arrows={false} duration={10000} easing="ease" className="">
                            <div className="px-2 lg:px-12 py-20">
                                <div>
                                    <p className="text-sm subtitle uppercase cw-text-gray">Challenge #1 in your CBD Business</p>
                                    <h2 className="section-title font-bold mb-3 max-w-lg">Finding Merchant Services that you can rely on</h2>
                                    <p>
                                        The CBD market has exploded. The passing of the Farm Bill has been a blessing for so many, however; it doesn't 
                                        provide the protections to the CBD industry that it should. Although CBD has been removed from the controlled 
                                        substances list, banking is still a challenge due to the vast regulations in the industry.  
                                    </p>
                                </div>
                            </div>
                            <div className="px-2 lg:px-12 py-20">
                                <div>
                                    <p className="text-sm subtitle uppercase cw-text-gray">Challenge #2 in your CBD Business</p>
                                    <h2 className="section-title font-bold mb-3 max-w-lg">Finding a reliable partner for your merchant service depository accounts</h2>
                                    <p>
                                        Public perception of the CBD space is ever changing. The lack of understanding by financial institutions of not 
                                        only the Farm Bill, but also the hemp/CBD market in general, increases confusion and misunderstanding in the market. 
                                        Because of this, finding a financial institution that will see your business as a vital partnership not only working 
                                        with your merchant services, but also providing a safe place for your depository accounts.
                                    </p>
                                </div>
                            </div>
                            <div className="px-2 lg:px-12 py-20">
                                <div>
                                    <p className="text-sm subtitle uppercase cw-text-gray">Challenge #3 in your CBD Business</p>
                                    <h2 className="section-title font-bold mb-3 max-w-lg">Finding reliable e-commerce solutions and in-store credit card processing</h2>
                                    <p>
                                        Many banks shy away from the Hemp/CBD marketplace because the legality of Hemp/CBD is still unclear. Many standard 
                                        merchant processing institutions will either make your business jump through hoops to be able to process payments, 
                                        possibly shut you down or will not work with you at all.
                                    </p>
                                </div>
                            </div>
                        </Slide>
                    </div>
                    {/* <div className="lg:w-1/2 text-center mx-auto mt-10">
                        <p>We are a partner that specializes in the development, implementation, and management of electronic payment processing products 
                            and solutions.
                        </p>
                    </div> */}
                    
                    <div className='lg:w-1/2'>
                        {/* <h2 className="section-title font-bold mb-3">High Risk Payment Experts</h2>
                        <p>
                            Merchants that are considered high risk or hard to place are nothing new to us.  Our clients are in verticals 
                            that have challenges securing traditional merchant services and banking depository accounts. We pride ourselves 
                            on not only being the experts in this complex field, but also providing superior service and competitive pricing. 
                            CWA processed over 30M Transactions and over $700M in 2019 for High Risk industry merchants.
                        </p> */}
                    </div>
                </div>                    
            </section>
            <section className='relative bg-gray-50'>
                <div className="section mx-auto px-5 w-100">
                    <div className="w-100 mx-auto grid grid-cols-1 lg:grid-cols-2 section relative py-10 lg:py-20">
                        <div className="hidden lg:block h-full relative">
                            <img className="h-80 w-auto" src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1651180931/new-cwa/CBD/undraw_done_re_oak4_htcwb5.svg"/>
                        </div>
                        <div className="my-auto">
                            <div className="">
                                <h2 className="text-2xl font-bold mb-3">High Risk Payment Experts</h2>
                                <p>
                                    Merchants that are considered high risk or hard to place are nothing new to us.  Our clients are in verticals that have challenges 
                                    securing traditional merchant services and banking depository accounts. We pride ourselves on not only being the experts in this complex 
                                    field, but also providing superior service and competitive pricing.  CWA processed over 30M Transactions and over $700M in 2019 for High 
                                    Risk industry merchants.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            <section className='relative cbd-paymentsolutions-section'>
                <div className="section mx-auto px-5 py-20 w-100 overflow-hidden">
                    <div className="lg:w-1/2 text-center mx-auto mb-10">
                        {/* <p className="text-sm subtitle uppercase cw-text-gray">Who We Are</p> */}
                        <h2 className="section-title font-bold mb-3">Who We Are</h2>
                        <p>
                            We are the <u><b>LARGEST</b> High Risk processor in the U.S.</u> and have deep knowledge of the unique challenges facing merchants in 
                            specialty categories such as:
                        </p>
                    </div>
                    <div className='grid grid-cols-2 lg:grid-cols-4 gap-5 mx-auto text-center'>
                        <div className='bg-gray-50 rounded-lg p-5'>
                            <div className="mb-2">
                                <span><FontAwesomeIcon className="cw-bg-cbd rounded-full p-2 w-10 h-10 text-xl cw-text-greengradient" icon={faCreditCard} /></span>
                                {/* <span className='cw-bg-cbd rounded-full p-2 w-10 h-10 text-xl cw-text-greengradient'>
                                    <FontAwesomeIcon icon={fa1} />
                                    <FontAwesomeIcon icon={fa8} />
                                </span> */}
                            </div>
                            <p className='font-semibold'>Adult Products & Entertainment</p>
                        </div>
                        <div className='bg-gray-50 rounded-lg p-5'>
                            <div className="mb-2"><span><FontAwesomeIcon className="cw-bg-cbd rounded-full p-2 w-10 h-10 text-xl cw-text-greengradient" icon={faCannabis} /></span></div>
                            <p className='font-semibold'>Hemp & CBD</p>
                        </div>
                        <div className='bg-gray-50 rounded-lg p-5'>
                            <div className="mb-2"><span><FontAwesomeIcon className="cw-bg-cbd rounded-full p-2 w-10 h-10 text-xl cw-text-greengradient" icon={faDroplet} /></span></div>
                            <p className='font-semibold'>Vape/eCig/eJuice</p>
                        </div>
                        <div className='bg-gray-50 rounded-lg p-5'>
                            <div className="mb-2"><span><FontAwesomeIcon className="cw-bg-cbd rounded-full p-2 w-10 h-10 text-xl cw-text-greengradient" icon={faCapsules} /></span></div>
                            <p className='font-semibold'>Nutraceuticals</p>
                        </div>
                        <div className='bg-gray-50 rounded-lg p-5'>
                            <div className="mb-2"><span><FontAwesomeIcon className="cw-bg-cbd rounded-full p-2 w-10 h-10 text-xl cw-text-greengradient" icon={faGamepad} /></span></div>
                            <p className='font-semibold'>Gaming</p>
                        </div>
                        <div className='bg-gray-50 rounded-lg p-5'>
                            <div className="mb-2"><span><FontAwesomeIcon className="cw-bg-cbd rounded-full p-2 w-10 h-10 text-xl cw-text-greengradient" icon={faFootball} /></span></div>
                            <p className='font-semibold'>Fantasy Sports</p>
                        </div>
                        <div className='bg-gray-50 rounded-lg p-5'>
                            <div className="mb-2"><span><FontAwesomeIcon className="cw-bg-cbd rounded-full p-2 w-10 h-10 text-xl cw-text-greengradient" icon={faHeadset} /></span></div>
                            <p className='font-semibold'>E-Sports</p>
                        </div>
                    </div>
                    
                    {/* <img className="z-0 absolute -right-52 overflow-hidden" src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650993719/new-cwa/Vets/Lane_3000_ch82co.png'/> */}               
                    
                
                </div>
                <section className="cw-bg-greengradient">
                        <div className="w-100 mx-auto px-5 relative py-12 text-center space-y-5">
                        <h2 className="text-white text-4xl font-semibold">Get Started with CWA Today</h2>
                        <button className="btn-white">Request a quote</button>
                        </div>
                    </section>
            </section>

            {/* <section className='relative '>
                <div className="section mx-auto px-5 py-20 lg:py-36 w-100">
                    <div className='lg:w-1/2'>
                        <h2 className="section-title font-bold mb-3">Who We Are</h2>
                        <p className='mb-3'>
                            We are the <u><b>LARGEST</b> High Risk processor in the U.S.</u> and have deep knowledge of the unique challenges facing merchants in 
                            specialty categories such as:
                        </p>
                        <ul className='list-disc font-semibold pl-5'>
                            <li>Adult Products, Services & Entertainment</li>
                            <li>Hemp & CBD</li>
                            <li>Vape/eCig/eJuice</li>
                            <li>Nutraceuticals</li>
                            <li>Gaming</li>
                            <li>Fantasy Sports</li>
                            <li>E-Sports</li>
                        </ul>
                    </div>
                </div>
            </section>                 */}
            </Layout>
        </div>
    );
}

export default Index;